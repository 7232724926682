<template>
  <div class="contact-us-bg">
    <b-container>
      <b-card class="contact-card">
        <div
          id="contact-submit-form"
          method="post"
          class="row justify-content-center align-items-center"
        >
          <div class="col-12 col-md-7">
            <div class="row text-center">
              <div class="col-md-12">
                <p class="h1 mb-4 contact-us-h1">GET IN TOUCH</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <b-form-input
                  type="text"
                  v-model="contact_form.firstname"
                  placeholder="First Name"
                  name="firstname"
                  class="contact-input-field"
                  required
                />
              </div>
              <div class="col-md-6 mb-3">
                <b-form-input
                  type="text"
                  v-model="contact_form.lastname"
                  placeholder="Last name"
                  name="lastname"
                  class="contact-input-field"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <b-form-input
                  type="text"
                  v-model="contact_form.email"
                  placeholder="Email"
                  name="email"
                  class="contact-input-field"
                  required
                />
              </div>

              <div class="col-md-6 mb-3">
                <b-form-input
                  type="text"
                  v-model="contact_form.phone"
                  placeholder="Phone"
                  name="phone"
                  class="contact-input-field"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-3">
                <b-form-input
                  type="text"
                  v-model="contact_form.subject"
                  placeholder="Subject"
                  name="subject"
                  class="contact-input-field"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <b-form-textarea
                  type="text"
                  v-model="contact_form.description"
                  placeholder="How we can assist you"
                  name="description"
                  class="contact-text-area-field"
                  required
                ></b-form-textarea>
              </div>
            </div>
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="sitekey"
              @verify="recaptchaVerified"
              @expired="recaptchaExpired"
              @error="recaptchaFailed"
              class="my-2 w-100"
            />

            <b-btn
              id="submit-button"
              class="download-button submit-button hover mb-4"
              @click="submitform()"
            >
              <span class="submit-text">SUBMIT</span>
            </b-btn>
          </div>
          <div class="col-12 col-md-5">
            <img src="img/getintouch.png" width="100%" />
          </div>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import axios from "axios";

export default {
  components: { VueRecaptcha },

  data: () => ({
    sitekey: "6LciSTknAAAAAPhXVNisxcboG9nbt89uKK9oxZwC",
    contact_form: {
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      subject: null,
      description: null,
      recaptchaVerified: false,
      gRecaptchaResponse: null,
    },
  }),

  methods: {
    recaptchaVerified(response) {
      console.log("res=" + JSON.stringify(response));
      this.contact_form.gRecaptchaResponse = response;
      this.contact_form.recaptchaVerified = true;
    },
    recaptchaExpired() {
      this.contact_form.recaptchaVerified = true;
    },
    recaptchaFailed() {
      this.contact_form.recaptchaVerified = false;
    },
    submitform() {
      if (
        this.contact_form.firstname == null ||
        this.contact_form.firstname == ""
      ) {
        this.$bvToast.toast("Firstname is required field to fill in", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else if (
        this.contact_form.lastname == null ||
        this.contact_form.lastname == ""
      ) {
        this.$bvToast.toast("Lastname is required field to fill in", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else if (
        this.contact_form.email == null ||
        this.contact_form.email == ""
      ) {
        this.$bvToast.toast("Email is required field to fill in", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else if (
        this.contact_form.phone == null ||
        this.contact_form.phone == ""
      ) {
        this.$bvToast.toast("Phone is required field to fill in", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else if (
        this.contact_form.subject == null ||
        this.contact_form.subject == ""
      ) {
        this.$bvToast.toast("Subject is required field to fill in", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else if (
        this.contact_form.description == null ||
        this.contact_form.description == ""
      ) {
        this.$bvToast.toast("Description is required field to fill in", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else if (
        this.contact_form.gRecaptchaResponse == null ||
        this.contact_form.recaptchaVerified == false
      ) {
        this.$bvToast.toast("ReCaptcha verification is failed", {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } else {
        axios
          .post("/contact_us", this.contact_form)
          .then((response) => {
            console.log(response);
            //refresh page
            this.$bvToast.toast("Something went wrong please try again!", {
              variant: "danger",
              noCloseButton: true,
              solid: true,
            });
            // window.location.reload();
          })
          .catch((error) => {
            console.log("msg=" + JSON.stringify(error.response));
            this.loading = false;
            this.$bvToast.toast("Something went wrong please try again!", {
              variant: "danger",
              noCloseButton: true,
              solid: true,
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.contact-us-bg {
  background-image: url(/img/Background.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 66px 0px;
}

.contact-us-h1 {
  font-weight: 800;
  font-size: clamp(32px, 5vw, 50px);
  line-height: clamp(40px, 5vw, 73px);
  /* or 146% */

  color: #33495c;
  backdrop-filter: blur(1.62379px);
}

.contact-input-field {
  font-weight: 400;
  line-height: 30px;
  width: 100%;
  border-radius: 100px;
  background-color: #e6e6e6;
  min-height: 40px;
  height: 100%;
  padding: 10px 15px;
  font-size: clamp(13px, 2vw, 16px);
}

.contact-text-area-field {
  font-weight: 400;
  line-height: 30px;
  width: 100%;
  border-radius: 10px;
  background-color: #e6e6e6;
  font-size: clamp(13px, 2vw, 16px);
}

.contact-text-area-field::-webkit-input-placeholder {
  color: #a2a2a2;
  font-size: clamp(13px, 2vw, 16px);
}
.contact-input-field::-webkit-input-placeholder {
  color: #a2a2a2;
  font-size: clamp(13px, 2vw, 16px);
}

.submit-button {
  background: linear-gradient(99.13deg, #1088d6 6.21%, #1256d3 91.79%),
    linear-gradient(122.29deg, #13e3b1 14.46%, #0c94b1 78.74%),
    linear-gradient(113.74deg, #f9ab7d 27.92%, #f95e70 69.53%);
  border-radius: 100px;
  color: #fff;
  box-shadow: -2px -4px 0px 0px #00000040 inset;
  max-width: 240px;
  width: 100%;
  text-align: center;
  font-size: clamp(15px, 4vw, 20px);
  padding: 10px 0px;
  font-weight: 600;
  margin-top: 10px;
}

.contact-card {
  padding: 50px 30px;
}

.submit-text {
}

input,
textarea {
  background-color: #d1d1d1;
}

@media only screen and (max-width: 767px) {
  .submit-button {
    max-width: 100%;
  }
}
</style>
